export default function TermsOfUse() {
    return (
        <div className={'flex justify-center my-10'}>
            <div className={'container'}>
                <p className={'font-bold text-2xl'}>Пользовательское соглашение</p>
                <br/>
                <p className={'font-bold text-xl'}>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p>Настоящее Пользовательское соглашение (далее - <strong>«Соглашение»</strong>) регулирует отношения между Тезис и Пользователями по поводу использования Онлайн-сервиса.</p>
                <p>Перед использованием Онлайн-сервиса Пользователь обязан ознакомиться с настоящим Соглашением и в случае согласия с его положениями присоединиться к нему путем регистрации в Онлайн-сервисе.</p>
                <p>Присоединяясь к настоящему Соглашению, Пользователь выражает полное и безоговорочное согласие со всеми его условиями, в том числе в части предоставления согласия Тезис на обработку персональных данных Пользователя и на получение рассылок информационного и рекламного содержания.</p>
                <p>В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации условия настоящего Соглашения являются публичной офертой.</p>
                <br/>
                <p className={'font-bold text-xl'}>2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>
                <p>2.1. В настоящем Соглашении термины, указанные с заглавной буквы имеют следующие значения:</p>
                <p>1) <strong>«Бронирование»</strong> означает предварительный заказ одного из свободных Парковочных мест на Коммерческой парковке на соответствующий Срок пользования Парковочным местом.</p>
                <p>2) <strong>«Карточка Коммерческой парковки»</strong> означает страницу Коммерческой парковки в Онлайн-сервисе, которая содержит общую информацию о Коммерческой парковке, в том числе правила пользования Коммерческой парковкой, тарифы, а также условия, на которых осуществляется Бронирование Парковочных мест. Карточка Коммерческой парковки является неотъемлемой частью настоящего Соглашения. Присоединяясь к настоящему Соглашению, Пользователь принимает условия, содержащиеся в Карточке Коммерческой парковки. В случае, если условия, изложенные в Карточке Коммерческой парковки, противоречат или не соответствуют условиям Пользовательского соглашения, подлежат применению условия, содержащиеся в Карточке Коммерческой парковки.</p>
                <p>3) <strong>«Коммерческая парковка»</strong> означает здание (строение или сооружение), часть здания (строения или сооружения) или специальную открытыую площадку, предназначенную для стоянки транспортных средств на платной основе.</p>
                <p>4) <strong>«Онлайн-сервис»</strong> означает сайт <i>Тезис</i>, предназначенный для поиска, Бронирования и оплаты Парковочных мест. Правообладателем Онлайн-сервиса является Тезис</p>
                <p>5) <strong>«Тезис»</strong> означает Индивидуальный Предприниматель Пиняскин Илия Станиславович (зарегистрированный в соответствии с законодательством Российской Федерации за основным государственным номером индивидуального предпринимателя (ОГРНИП): 324665800100482, идентификационный номер налогоплательщика (ИНН): 450206185693</p>
                <p>6) <strong>«Собственник»</strong> означает третье лицо, которое является собственником или иным законным владельцем Коммерческой парковки на праве собственности или ином праве.</p>
                <p>7) <strong>«Парковочное место»</strong> означает парковочное место на Коммерческой парковке, предназначенное для стоянки транспортного средства.</p>
                <p>8) <strong>«Пользователь»</strong> означает полностью дееспособное физическое лицо или юридическое лицо, установившее и (или) использующее Онлайн-сервис.</p>
                <p>9) <strong>«Рабочий день»</strong> означает календарный день, кроме субботы, воскресенья (за исключением случаев, когда суббота или воскресенье являются рабочими днями) и нерабочих (праздничных) дней согласно законодательству Российской Федерации.</p>
                <p>10) <strong>«Соглашение»</strong> имеет значение, указанное во вступительной части настоящего Соглашения.</p>
                <p>11) <strong>«Срок пользования Парковочным местом»</strong> означает срок, в течение которого Пользователь, забронировавший и оплативший Парковочное место, вправе пользоваться им для стоянки транспортного средства.</p>
                <p>12) <strong>«Срок пользования Парковочным местом»</strong> означает Тезис и Пользователя.</p>
                <br/>
                <p className={'font-bold text-xl'}>3. РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ ПОЛЬЗОВАТЕЛЯ. УЧЕТНАЯ ЗАПИСЬ</p>
                <p>3.1. Для использования Онлайн-сервиса Пользователь должен пройти регистрацию в Онлайн-сервисе для создания уникальной учетной записи.</p>
                <p>3.2. Для регистрации Пользователь обязуется предоставить полную и достоверную информацию о себе по вопросам, предлагаемым в форме регистрации. Пользователь обязуется поддерживать эту информацию в актуальном состоянии. В случае наличия у Тезис серьезных оснований полагать, что предоставленная Пользователем информация является недостоверной, Тезис имеет право отказать Пользователю в регистрации либо ограничить его доступ к Онлайн-сервису.</p>
                <p>3.3. В целях проверки указанного Пользователем при регистрации в Онлайн-сервисе номера телефона Пользователь соглашается с получением сообщения в Telegram с кодом подтверждения. Пользователь подтверждает, что аккаунт в Telegram привязан и подтвержден с помощью номера телефона, принадлежащего Пользователю.</p>
                <p>3.4. Пользователь несет ответственность за все действия (а также их последствия) при использовании Онлайн-сервиса под учетной записью Пользователя, включая случаи добровольной передачи Пользователем учетных данных для доступа к учетной записи Пользователя третьим лицам.</p>
                <p>3.5. Пользователь соглашается с тем, что он обязан немедленно уведомить Тезис о любом случае неавторизованного (не разрешенного им) доступа к его учетной записи и (или) о любом ином нарушении безопасности.</p>
                <p>3.6. Пользователь вправе в любой момент удалить свою учетную запись.</p>
                <br/>
                <p className={'font-bold text-xl'}>4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ОНЛАЙН-СЕРВИСА</p>
                <p>4.1. Загрузка и использование Пользователем Онлайн-сервиса осуществляется бесплатно.</p>
                <p>4.2. Онлайн-сервис содержит охраняемые законом результаты интеллектуальной деятельности, в том числе тексты, фотографии и графические изображения.</p>
                <p>4.3. Тезис принадлежит авторское право на использование содержания Онлайн-сервиса (в том числе право на подбор, расположение, систематизацию и преобразование данных, а также на сами исходные данные).</p>
                <p>4.4. Пользователь не имеет права вносить изменения, публиковать, передавать третьим лицам, участвовать в продаже или уступке, создавать производные продукты или иным образом использовать, частично или полностью, содержание Онлайн-сервиса.</p>
                <p>4.5. Пользователь Сети обязуется использовать Онлайн-сервис только в законных целях.</p>
                <p>4.6. При использовании Онлайн-сервиса Пользователь не вправе выдавать себя за другое физическое лицо.</p>
                <p>4.7. Пользователь не должен каким-либо способом размещать и (или) распространять компьютерные программы либо иную компьютерную информацию, заведомо предназначенную для несанкционированного уничтожения, блокирования, модификации, копирования компьютерной информации или нейтрализации средств защиты компьютерной информации.</p>
                <p>4.8. Пользователь самостоятельно через свой личный кабинет осуществляет Бронирование Парковочного места на Коммерческой парковке на соответствующий Срок пользования Парковочным местом (при условии наличия на соответствующей Коммерческой парковке свободных Парковочных мест в течение предполагаемого Срока пользования Парковочным местом). Бронирование Парковочного места осуществляется на условиях, указанных в Карточке Коммерческой парковки.</p>
                <p>4.9. Оплата выбранного Пользователем Парковочного места может быть осуществлена Пользователем с помощью банковской карты или с помощью электронных средств платежа. Во избежание сомнений, Парковочное место считается забронированным Пользователем только при его условии его оплаты в полном размере.</p>
                <p>4.10. В своем личном кабинете Пользователь вправе в любое время до начала течения Срока пользования Парковочным местом отменить Бронирование и получить возврат уплаченной суммы.</p>
                <p>4.11. Пользователь не вправе требовать возврата денежных средств за Бронирование, которым Пользователь не воспользовался по своей вине и/или по независящим от Онлайн-сервиса причинам.</p>
                <p>4.12. Пользователь, забронировавший и осуществивший оплату Парковочного места, в полном объеме и безоговорочно соглашается с правилами пользования соответствующей Коммерческой парковкой, установленными собственником или иным законным владельцем такой Коммерческой парковки.</p>
                <p>4.13. В течение Срока пользования Парковочным местом Пользователь вправе продлить Срок пользования Парковочным местом при наличии свободных парковочных мест на Срок пользования Парковочным местом. При Краткосрочном Бронировании продление Срока пользования Парковочным местом осуществляется по тарифу, установленному для изначально забронированного Срока пользования Парковочным местом. При Долгосрочном Бронировании продление Срока пользования Парковочным местом осуществляется по тарифу, действующему на соответствующей Коммерческой парковке на дату оплаты продления Срока пользования Парковочным местом.</p>
                <p>4.14. Пользователь соглашается на передачу платежных данных банковскому оператору АО "Тинькофф Банк" при совершении платежа с помощью банковской карты или с помощью электронных средств платежа.</p>
                <p>4.15. Пользователь вправе запрашивать всю необходимую информацию и реквизиты Собственника Коммерческой парковки.</p>
                <br />
                <p className={'font-bold text-xl'}>5. ОНЛАЙН-СЕРВИС И КОНТЕНТ ТРЕТЬИХ ЛИЦ</p>
                <p>5.1. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная в Онлайн-сервисе, не является одобрением или рекомендацией данных сайтов, продуктов, услуг либо информации со стороны Тезис, за исключением случаев, когда на это прямо указывается в Онлайн-сервисе.</p>
                <p>5.2. Онлайн-сервис не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием Онлайн-сервиса, включая, но не ограничиваясь, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.</p>
                <br />
                <p className={'font-bold text-xl'}>6. ОТВЕТСТВЕННОСТЬ</p>
                <p>6.1. Пользователь использует Онлайн-сервис на свой собственный риск. Онлайн-сервис предоставляется «как есть».</p>
                <p>6.2. Тезис не несет ответственность за сроки и качество оказания услуг банками и платежными системами, а также за неоказание услуг такими лицами.</p>
                <p>6.3. Ни Тезис, ни Собственники не обеспечивают сохранность транспортных средств и (или) иного имущества, размещенного на территории Коммерческой парковки, и не несут ответственности за их сохранность.</p>
                <p>6.4. Ни Тезис, ни Собственники не несут ответственность за причинение вреда здоровью и (или) имуществу Пользователей, причиненного в результате дорожно-транспортного происшествия вблизи и (или) на территории Коммерческой парковки.</p>
                <p>6.5. За неисполнение или ненадлежащее исполнение условий настоящего Соглашения Стороны несут ответственность в соответствии с законодательством Российской Федерации.</p>
                <p>6.6. Стороны освобождаются от ответственности, если обязательства не исполнены или исполнены ненадлежащим образом из-за обстоятельств непреодолимой силы, возникших после заключения настоящего Соглашения. К таким обстоятельствам относятся в том числе сбои в сети «Интернет», издание акта органа государственной власти или органа местного самоуправления, а также иные обстоятельства, относящиеся к обстоятельствам непреодолимой силы.</p>
                <p>6.7. Тезис имеет право заблокировать Пользователя как на временной, так и на постоянной основе в случае нарушения Пользователем правил использования Онлайн-сервисом и (или) правил пользования соответствующей Коммерческой парковкой.</p>
                <p>6.8. Тезис лишь предоставляет Пользователю возможность Бронирования Парковочного места на территории Коммерческой парковки, принадлежащей Собственнику. Тезис выступает в качестве агента между Пользователем и Собственником. Наименование Собственника указывается в Карточке Коммерческой парковки.</p>
                <br/>
                <p className={'font-bold text-xl'}>7. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</p>
                <p>7.1. Персональные данные Пользователя обрабатываются в соответствии с Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных» и утвержденной Тезис Политикой конфиденциальности.</p>
                <p>7.2. Тезис имеет право от своего имени направлять Пользователю (на электронную почту Пользователя либо с использованием мессенджеров) самостоятельно или с привлечением технических партнеров информационные и рекламные сообщения.</p>
                <br />
                <p className={'font-bold text-xl'}>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
                <p>8.1. Настоящее Соглашение вступает в силу с момента установки Пользователем Онлайн-сервиса и действует в течение всего срока использования Пользователем Онлайн-сервиса.</p>
                <p>8.2. Во всем, что не предусмотрено настоящим Соглашением, Стороны руководствуются действующим законодательством Российской Федерации.</p>
                <p>8.3. Ввиду безвозмездности настоящего Соглашения, нормы о защите прав потребителей не могут быть применены к отношениям между Тезис и Пользователем.</p>
                <p>8.4. По вопросам, связанным с исполнением настоящего Соглашения, необходимо обращаться по адресу места нахождения Тезис, либо по адресу электронной почты corp@thesispark.ru.</p>
                <br />
                <p className={'font-bold text-xl'}>КОНТАКТНЫЕ ДАННЫЕ И РЕКВИЗИТЫ</p>
                <p>Индивидуальный предприниматель Пиняскин Илия Станиславович</p>
                <p>ИНН: 450206185693</p>
                <p>ОГРНИП: 324665800100482</p>
                <p>Адрес: Свердловская область, город Екатеринбург</p>
                <p>Электронный адрес: corp@thesispark.ru</p>
                <p>Номер телефона: +7 (922) 166-68-13</p>
                <i>Редакция от «14» ноября 2024 г.</i>
            </div>
        </div>
    )
}